/* VARIABLES */

:root {
  //colors

  --primary-font-family: "Inter", sans-serif;
  --secondary-font-family: "Roboto", sans-serif;
  --Colors-Dark-green: #0F1E00;
  --Colors-White: #FFF;
  --Colors-Primary: #70B32F;
  --Colors-Grey: #F6F6F6;

  //gradients

  --gradient-green: linear-gradient(
    84deg,
    #50d873 1.86%,
    rgba(80, 216, 115, 0.02) 101.78%
  );

  --gradient-card: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  --gradient-white: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    #fff 100%
  );

  //font-sizes
  --fs-3xsm: 0.78125vw;
  --fs-2xsm: 1.0416vw;
  --fs-xsm: 1.171875vw;
  --fs-sm: 1.5625vw;
  --fs-md: 2.083vw;
  --fs-lg: 2.34375vw;
  --fs-xlg: 2.60416vw;
  --fs-2xlg: 5.2083vw;

  //screens

  --lg-laptop: @media only screen and (max-width: 1400px);
  --md-laptop: @media only screen and (max-width: 1200px);
  --sm-laptop: @media only screen and (max-width: 992px);
  --lg-tablet: @media only screen and (max-width: 720px);
  --md-tablet: @media only screen and (max-width: 580px);
  --sm-tablet: @media only screen and (max-width: 460px);
  --md-mobile: @media only screen and (max-width: 350px);
  --lg-mobile: @media only screen and (max-width: 380px);
  --sm-mobile: @media only screen and (max-width: 320px);
}
