@media only screen and (max-width: 992px) {
    .catalog-categories {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 24px;
      
        .category-btn {
          margin-right: 5px;
          border-radius: 50px;
          background: var(--Colors-Grey, #f6f6f6);
          color: var(--Colors-Dark-green, #0f1e00);
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          padding: 8px 12px;
          cursor: pointer;
          transition: all ease 0.3s;
          margin-bottom: 20px;
        }
      
        .selectedCategory {
          background: var(--Colors-Primary, #70b32f);
          color: var(--Colors-White, #fff);
        }
      }
}