.footer {
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin-top: 50px !important;
}
.top_cont {
  margin-top: 50px !important;
  display: flex;
  align-items: center;
  flex-direction: column;
  svg:nth-child(1) {
    animation: to-top infinite 1s;
    -webkit-animation: to-top infinite 1s;
    height: 55px;
    width: 55px;
    path{
      fill: #f09900
    }
}
  @keyframes to-top {
    0% {
      transform: translateY(0px);
      -webkit-transform: translateY(0px);
      -moz-transform: translateY(0px);
      -ms-transform: translateY(0px);
      -o-transform: translateY(0px);
}
    50% {
      transform: translateY(10px);
      -webkit-transform: translateY(10px);
      -moz-transform: translateY(10px);
      -ms-transform: translateY(10px);
      -o-transform: translateY(10px);
}

    100% {
      transform: translateY(0px);
      -webkit-transform: translateY(0px);
      -moz-transform: translateY(0px);
      -ms-transform: translateY(0px);
      -o-transform: translateY(0px);
}
  }
}
.to-top {
  cursor: pointer;
  display: flex;
  width: 100%;
  background-color: #f09900;
  justify-content: center;
  align-items: center;
  padding: 5px 0 !important;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  svg {
    height: 25px !important;
    width: 25px !important;
    animation: none !important;
    -webkit-animation: none !important;
    path {
      fill: #fff !important;
    }
  }
}

.footer-bottom {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px !important;
  font-size: 15px;
  font-weight: 550;
  color: #0f1e00;
}
.footer-bottom a {
  text-decoration: underline;
  &:hover {
    color: #800080;
  }
}
.footer__left {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 25px;
  background: #f09900;
  height: 420px;
}
.left_top {
  padding: 40px;
  border-bottom: 2px solid #f6f6f63a;
}
.left_top_wrappers {
  display: flex;
  padding: 0 0 25px 0;
}
.left_top_wrappers span {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.footer__left p {
  color: var(--Colors-White, #fff);
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.footer__left a {
  color: var(--Colors-White, #fff);
  font-family: "Inter";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px; /* 141.667% */
}
.footer_links {
  display: flex;
  align-items: center;
  gap: 15px;
  svg {
    width: 30px;
    height: 30px;
  }
}
.left_bottom {
  margin: 0 0 25px 0 !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 45px;
  width: 100%;
  color: var(--Colors-White, #fff);
  font-family: "Inter";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 166.667% */
}
.left_bottom img {
  width: 150px;
}
.left_bottom p {
  width: 50%;
}
.footer iframe {
  margin: 0 0 25px 0 !important;
  width: 45% !important;
  object-fit: cover;
  height: 420px;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
}
