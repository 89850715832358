@media only screen and (max-width: 992px) {
  .header-navbar {
    display: none;
  }

  .logo-image {
    width: 90px;
  }

  .lang-select {
    display: none;
  }

  .burger-btn {
    display: flex;
  }

  .icon-btn {
    width: 38px;
    height: 38px;
    
  }

  .back-btn {
    width: 25vw !important;
    height: 35px !important;
    margin-right: 5px !important;
    svg{
      width: 25px;
      height: 25px;
    }
  }
}

@media only screen and (max-width: 576px) {
  .cart-modal {
    .modal-container.show {
      transform: translateX(0%);
      left: 50%;
      transform: translateX(-50%);
    }

    .modal-container {
      top: 10px;
      width: 94%;
    }

    .modal-top-section {
      padding: 10px 40px;
    }

    .modal-mid-section {
      padding: 12px 40px 12px 40px;
      height: 48vh;
    }

    &__title {
      font-size: 22px;
    }

    &__subtitle {
      font-size: 14px;
      line-height: 18px;
    }

    &__item {
      margin-bottom: 12px;

      .left-side {
        margin-right: 12px;
        padding: 8px;
        display: inline-block;
        img {
          width: 50px;
          height: 50px;
        }
      }

      .right-side {
        .cart-amount-controller {
          span {
            font-size: 16px;
            cursor: pointer;
          }

          .counter-amount {
            font-size: 12px;
            margin: 0 7px;
          }
        }
      }
    }

    .modal-bottom-section {
      padding: 20px 40px;

      .submit-form-btn {
        padding: 10px 0;
        font-size: 14px;
        margin-top: 15px;
      }
    }

    .close-btn {
      width: 10px;
    }
  }
}

@media only screen and (max-width: 375px) {
  .cart-modal {
    .modal-mid-section {
      padding: 12px 40px 12px 40px;
      height: 45vh;
    }
  }

  .logo-image {
    width: 75px;
  }

  .icon-btn {
    width: 30px;
    height: 30px;
  }

  .back-btn {
    width: 37px;
  }
}

@media only screen and (max-width: 256px) {
  .cart-modal {
    .modal-mid-section {
      height: 40vh;
    }
  }
}
