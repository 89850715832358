.three_blogs {
  display: flex;
  flex-direction: column;
  margin-top: 80px;
}
.three_blogs_cards {
  margin: 50px 0;
  display: grid;
  gap: 25px;
  grid-template-columns: repeat(3, 1fr);
  .blog_card:first-child{
    grid-column: span 1;
  }
}
.three_blogs_title {
  display: flex;
  color: var(--Colors-Dark-green, #0f1e00);
  font-family: "Inter";
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  align-items: center;
  gap: 5px;
}
.blog_page {
  display: flex;
  flex-direction: column;
  margin-top: 70px;
}
.blog_page_title {
  color: var(--Colors-Dark-green, #0f1e00);
  text-align: center;
  font-family: "Inter";
  font-size: 60px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.blog_cards {
  margin: 100px 0;
  display: grid;
  gap: 25px;
  grid-template-columns: repeat(3, 1fr);
}
.blog_card:first-child {
  grid-column: span 2;
}
.blog_card {
  display: flex;
  border-radius: 25px;
  border: 2px solid var(--Colors-Grey, #f6f6f6);
  flex-direction: column;
  justify-content: space-between;
  min-height: 600px;
  flex-shrink: 0;
}
.blog_img {
  width: 100%;
  object-fit: cover;
  height: 288px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}
.blog_title {
  color: var(--Colors-Dark-green, #0f1e00);
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  padding: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 80px;
}
.blog_desc {
  color: var(--Colors-Dark-green, #0f1e00);
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
  padding: 20px;
  height: 100px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.blog_btn {
  display: flex;
  margin: 25px;
  border-radius: 50px;
  border: 1px solid var(--Colors-Primary, #70b32f);
  display: flex;
  padding: 13px 24px;
  justify-content: space-between;
  align-items: center;
  color: var(--Colors-Dark-green, #0f1e00);
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  svg {
    width: 20px;
    height: 20px;
  }
  transition: all ease-in-out 0.5s;
  -webkit-transition: all ease-in-out 0.5s;
  -moz-transition: all ease-in-out 0.5s;
  -ms-transition: all ease-in-out 0.5s;
  -o-transition: all ease-in-out 0.5s;
}
.blog_btn:hover {
  background: #70b32f;
  color: #f6f6f6;
  svg {
    background: #f6f6f6;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
  }
}
.details_page {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}
.details_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.back_link {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #000;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.back_link:hover {
  color: #70b32f;
  svg {
    path {
      fill: #70b32f;
    }
  }
}

.details_date {
  color: #000;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.details_body {
  display: flex;
  flex-direction: column;
  margin-top: 55px;
}
.details_body__name {
  color: #000;
  font-family: "Inter";
  font-size: 80px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 80%;
  margin-bottom: 50px;
}
.details_body__desc {
  color: #000;
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px; /* 170% */
  width: 80%;
  place-self: center;
  margin: 80px 0;
}
.details_body__img {
  border-radius: 25px;
  width: 100%;
  height: 476px;
  object-fit: cover;
}
.details_images {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  place-items: center;
  height: 467px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
  }
}
@media only screen and (max-width: 992px) {
  .three_blogs_cards {
    margin: 50px 0;
    display: grid;
    gap: 25px;
    grid-template-columns: repeat(1, 1fr);
    .blog_card:first-child{
      grid-column: span 1;
    }
  }
  .blog_page {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
  }
  .blog_page_title {
    color: var(--Colors-Dark-green, #0f1e00);
    text-align: center;
    font-family: "Inter";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .blog_cards {
    margin: 50px 0;
    display: grid;
    gap: 25px;
    grid-template-columns: repeat(1, 1fr);
  }
  .blog_card:first-child {
    grid-column: span 1;
  }
  .details_body__name {
    color: var(--Colors-Dark-green, #0f1e00);
    font-family: "Inter";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 34px; /* 141.667% */
    width: 80%;
    margin-bottom: 50px;
  }
  .details_body__desc {
    color: #000;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
    width: 100%;
    place-self: center;
    margin: 20px 0;
  }
  .details_body__img {
    height: 400px;
  }
  .details_images {
    display: flex;
    flex-direction: column;
    img {
      height: 400px;
    }
  }
}
