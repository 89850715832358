.container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0;
}

@media only screen and (max-width: 1400px) {
  .container {
    max-width: 1200px;
    padding: 0 15px;
  }
}
@media only screen and (max-width: 1200px) {
  .container {
    max-width: 1150px;
  }
}
@media only screen and (max-width: 992px) {
  .container {
    max-width: 890px;
  }
}
@media only screen and (max-width: 768px) {
  .container {
    max-width: 740px;
  }
}
@media only screen and (max-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media only screen and (max-width: 425px) {
  .container {
    max-width: 400px;
  }
}
@media only screen and (max-width: 375px) {
  .container {
    max-width: 330px;
  }
}