@media only screen and (max-width: 992px) {
  .footer {
    display: flex;
    flex-direction: column-reverse;
  }
  .footer-bottom{
    flex-direction: column;
    text-align: center;
    font-size: 15px;
    margin-bottom: 45px !important;
  }
  .footer__left {
    width: 100%;
    height: max-content;
    margin-bottom: 20px;
  }
  .left_top_wrappers {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
  .left_top_wrappers span {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 35px;
  }
  .left_top {
    padding: 20px 20px 0px 20px;
    border-bottom: 2px solid #f6f6f63a;
  }
  .left_bottom {
    margin: 0 0 20px 0 !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 20px;
  }
  .left_bottom p {
    width: 80%;
    margin-top: 20px;
  }
  .footer iframe {
    width: 100% !important;
  }
}
