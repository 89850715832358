.header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px !important;
  z-index: 999;
}
.logo-image {
  width: 120px;
}
.header-navbar {
  // padding: 12px 40px 14px 40px;
  background: var(--Colors-Grey, #f6f6f6);
  border-radius: 48px;
  width: fit-content;
  justify-content: center;
  position: relative;

  &__list {
    margin: 0 15px;
    display: flex;
    align-items: center;
    place-self: center;
    place-content: center;

    // .ant-input {
    //   outline: none !important;
    //   border: none !important;
    //   &:focus {
    //     border-color: transparent;
    //     box-shadow: none;
    //     outline: none;
    //   }
    // }

    .ant-input-outlined {
      outline: none !important;
      border: none !important;
      &:focus {
        border-color: transparent;
        box-shadow: none;
        outline: none;
      }
    }
  }

  &__list-item {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    // margin-right: 50px;
    transition: all ease 0.3s;
    padding: 12px 25px 14px 25px;
    border-bottom: 2px solid transparent;

    &:last-child {
      margin-right: 0px;
    }

    a {
      color: var(--Colors-Dark-green, #0f1e00);
      &:hover {
        color: var(--Colors-Primary, #70b32f);
      }
    }
  }

  .selectedMenu {
    border-bottom: 2px solid #70b32f;

  }

  &__search-btn {
    padding: 0 25px;
    border-left: 2px solid #dfe0dd;

    // &:before {
    //   content: "";
    //   width: 2px;
    //   height: 20px;
    //   background-color: #dfe0dd;
    //   position: absolute;
    //   left: -26px;
    // }
  }

  &__search-btn-mobile {
    padding: 0 25px;
    // border-left: 2px solid #dfe0dd;
  }
}

.header-searchbar {
  width: 610px !important;
  border: none;
  outline: none;
  background: var(--Colors-Grey, #f6f6f6);
  padding: 12px 12px;
  width: 100%;
  position: relative;
  

  &:hover {
    background-color: var(--Colors-Grey, #f6f6f6);
  }

  &:focus {
    outline: none;
    border: none;
    background-color: var(--Colors-Grey, #f6f6f6);
  }

  &:active {
    outline: none;
  }

  .nodata {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.icon-btn {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f6f6f6;
  border-radius: 50%;
  cursor: pointer;
}

.cart-btn {
  margin-left: 12px;
}

.search-btn {
  display: none;
  @media only screen and (max-width: 992px) {
    display: flex;
    margin-left: 12px;
  }
}

.burger-btn {
  display: none;
}

.search-modal {
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  z-index: 999;
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px;
  padding: 12px;
  overflow: auto !important;

  &__top {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 10px;

    &:after {
      content: "";
      width: 20px;
      height: 20px;
      position: absolute;
      background-image: url("../../assets/images/icons/search-icon.svg");
      background-repeat: no-repeat;
      background-size: contain;
      right: 20px;
      bottom: 10px;
    }

    .header-searchbar {
      border-radius: 44px;
      background: var(--Colors-Grey, #f6f6f6);
      padding: 7px 60px 10px 20px;
    }
  }

  &__bottom {
    height: 89vh;
    overflow-y: auto;
    .nodata {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.mobile-menu {
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  z-index: 999;
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 12px !important;
  transition: transform 0.3s ease;
  transform: translateX(-120%);

  &__top {
    display: flex;
    justify-content: space-between;
  }
  &__bottom {
    margin-top: 40px !important;
  }

  .header-navbar__list-item {
    margin-bottom: 30px;
    width: fit-content;
    text-wrap: nowrap;
  }
}

.mobile-menu.show {
  transform: translateX(0%);
}

.cart-modal {
  position: absolute;

  .modal-overlay.show {
    display: block;
  }

  .modal-container.show {
    // display: block;
    transform: translateX(0%);
  }

  .show {
    display: block;
    transform: translateX(0);
  }

  .modal-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    transition: opacity 0.3s ease;
  }

  .modal-container {
    // display: none;
    position: fixed;
    top: 10px;
    right: 10px;
    width: 500px; /* Default width */
    height: 97%;
    background-color: white;
    z-index: 1000;
    transition: transform 0.3s ease;
    transform: translateX(120%);
    border-radius: 15px;
  }
  .modal-top-section {
    padding: 10px 40px;
    border-bottom: 2px solid #f6f6f6;
    .top-part {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .modal-mid-section {
    padding: 12px 40px 12px 40px;
    border-bottom: 2px solid #f6f6f6;
    height: 50vh;
    overflow-y: scroll;
  }

  &__title {
    color: var(--Colors-Dark-green, #0f1e00);
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  &__subtitle {
    color: var(--Colors-Dark-green, #0f1e00);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    margin-top: 8px;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .left-side {
      margin-right: 12px;
      padding: 14px;
      border-radius: 15px;
      border: 2px solid var(--Colors-Grey, #f6f6f6);
      display: inline-block;
      img {
        width: 63px;
        height: 63px;
      }
    }

    .right-side {
      display: flex;
      justify-content: center;
      flex-direction: column;
      .cart-amount-controller {
        display: flex;
        align-items: center;
        span {
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          cursor: pointer;
        }

        .disabled {
          opacity: 0.7;
        }

        .counter-amount {
          font-size: 18px;
          margin: 0 7px;
        }
      }
    }

    .delete-icon {
      cursor: pointer;
      transition: all ease 0.2s;
      &:hover {
        opacity: 0.4;
      }
    }
  }

  .modal-bottom-section {
    padding: 20px 40px;
    margin-top: auto;

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    .payment-input {
      border-radius: 12px;
      background: var(--Colors-Grey, #f6f6f6);
      padding: 12px 16px;
      padding-top: 25px;
      outline: none;
      border: none;
      width: 100%;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .payment-input-label {
      position: absolute;
      left: 15px;
      top: 6px;
      color: var(--Colors-Dark-green, #0f1e00);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      z-index: 99;
    }

    .submit-form-btn {
      border-radius: 35px;
      background: var(--Colors-Primary, #70b32f);
      padding: 14px 0;
      width: 100%;
      color: var(--Colors-White, #fff);
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      margin-top: 15px;
    }
  }

  .close-btn {
    width: 12px;
    cursor: pointer;
  }
}

.searched-items-wrapper {
  position: absolute;

  .nodata {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .searched-items-overlay.show {
    display: block;
  }

  .searched-items-container.show {
    display: flex;
  }

  .show {
    display: block;
    overflow-y: scroll !important;
    transform: translateX(0);
  }

  .searched-items-overlay {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 90%;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 0.3s ease;
  }

  .searched-items-container {
    display: none ;
    position: fixed;
    left: 50%;
    top: 70px;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 60%;
    background-color: white;
    transition: transform 0.3s ease;
    transform: translateX(-50%);
    border-radius: 0 0 15px 15px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 70px 0; 
    .catalog-list{
      padding: 70px 0 !important;
      position: absolute;
      bottom: 0;
      align-self: center;
      gap: 15px;
      display: grid;
      width: 100% !important;
      grid-auto-flow: unset !important;
      height: 100% !important;
    }

    @media only screen and (max-width: 576px) {
      height: 100vh;
    }
  }
}
